import styled from '@emotion/styled'

export const CoursesContainer = styled.div`
  position: relative;
  width: 100vw;
  display: flex;
  justify-content: center;
  text-align: center;
`

export const CoursesContent = styled.div`
  position: relative;
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    font-weight: normal;
    margin-bottom: 15px;
  }
`

export const CourseContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap-reverse;
  width: 100%;

  @media (max-width: 900px) {
    font-size: 0.8em;
  }
`

export const CourseImages = styled.div`
  display: flex;
  width: 25%;

  .course-image-container {
    height: 100%;

    img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  @media (max-width: 900px) {
    display: none;
  }
`

export const CourseCheckboxes = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .course-header {
    background-color: ${(props) => props.backgroundColor};
    padding: 0.75em 3em;
    margin: 0px;
  }

  h3 {
    margin: 0px;
  }

  .upgrade-header {
    max-width: 500px;
    text-align: left;
  }

  @media (max-width: 750px) {
    width: 100%;
  }
`

export const AllCourseCheckboxes = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 10px;
  margin-bottom: 15px;
  max-width: 500px;
`

export const UpgradeCourseCheckboxes = styled.div`
  max-width: 500px;
  width: 100%;
`

export const IndividualCheckBox = styled.div`
  display: flex;
  justify-content: flex-start;
  text-align: left;
  margin: 10px 0px;

  .checkicon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.backgroundColor};
    height: 1em;
    width: 1em;
    margin-right: 15px;
    border-radius: 2px;
  }

  .strikethrough {
    text-decoration: line-through;
  }
`

export const MoreCourseInfo = styled.div`
  margin-top: auto;
  font-size: 0.75em;
  display: flex;
  flex-direction: column;
  align-items: center;

  .down-arrow {
    position: relative;
    width: 40px;
    height: 40px;
    border-style: solid;
    border-width: 20px 20px 20px 20px;
    border-color: ${(props) => props.backgroundColor} transparent transparent
      transparent;
    margin-top: 5px;
  }
`

export const CourseSelection = styled.div`
  width: 25%;
  padding: 0px 15px;
  h3 {
    margin-top: 30px;
  }
  @media (max-width: 750px) {
    width: 100%;
  }
`

export const Price = styled.div`
  margin: 15px 0px;
  font-size: 4em;
`

export const MoreCourseDetailsContainer = styled.div`
  position: relative;
  width: 100vw;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 50px;
`
export const MoreCourseDetailsContents = styled.div`
  position: relative;
  width: 85%;
`
export const MoreCourseDetailsHeader = styled.div`
  width: 100%;
  background-color: ${(props) => props.backgroundColor};
  color: #ffffff;
  padding: 15px 0px;
`
export const MoreCourseDetailsCardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
export const MoreCourseDetailsCard = styled.div`
  width: 50%;
  background-color: #4f5359;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    margin: 15px 0px;
    text-align: center;
    font-size: 1.5em;
  }

  ul {
    list-style-type: none;
    width: 80%;
    text-align: left;
    margin: 20px 0px;
  }

  ul > li {
  text-indent: -5px;
  }
  ul > li:before {
    content: "-";
    text-indent: -5px;
  }

  .spaced-list {
    li {
      margin: 15px 0px;
    }
  }

  p {
    text-align: left;
    width: 90%;
  }

  img {
    max-height: 100%;
    max-width: 100%;
  }

  @media (max-width: 1200px) {
    width: 100%;

    img {
      width: 50%;
    }
  }

  @media (max-width: 600px) {}
    img {
      width: 100%;
    }
  }
`

export const BackToTop = styled.div`
  margin-top: auto;
  margin-bottom: 15px;
  font-size: 0.75em;
  display: flex;
  flex-direction: column;
  align-items: center;

  .up-arrow {
    position: relative;
    width: 40px;
    height: 40px;
    border-style: solid;
    border-width: 20px 20px 20px 20px;
    border-color: transparent transparent ${(props) => props.backgroundColor}
      transparent;
    margin-top: 5px;
  }

  a {
    text-decoration: underline;
  }
`
