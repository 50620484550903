import styled from '@emotion/styled'

export const PlayIconCircle = styled.div`
  position: absolute;
  width: 55px;
  height: 55px;
  left: 50%;
  bottom: 5%;
  background-color: #000000;
  transform: translate(-50%, 0%);
  border-radius: 50%;
  opacity: 0.7;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 1px 1px 5px #000000;

  label {
    cursor: pointer;
  }

  .playIconButton {
    position: relative;
    width: 40px;
    height: 40px;
    border-style: solid;
    border-width: 20px 0px 20px 40px;
    border-color: transparent transparent transparent #ffffff;
    opacity: 0.5;
    left: 3px;
  }
`
