// module imports
import React from 'react'
import { useTranslation } from 'react-i18next'

// local imports

// style imports
import {
  MoreCourseDetailsContainer,
  MoreCourseDetailsContents,
  MoreCourseDetailsHeader,
  MoreCourseDetailsCardContainer,
  MoreCourseDetailsCard,
} from 'components/styleComponents/CoursesPage/CoursesStyles'

// asset imports
import generalEnglishImage from 'images/png/generalEnglishMoreCourseDetails.png'
import academicEnglishImage from 'images/png/academicEnglishMoreCourseDetails.png'
import businessEnglishImage from 'images/png/businessEnglishMoreCourseDetails.png'
import individualEnglishImage from 'images/png/individualEnglishMoreCourseDetails.png'

const MoreCourseDetailsComponent = ({ courseCategory, backgroundColor }) => {
  const { t } = useTranslation('coursesPage')

  //this is a dictionary for looking up the description data for each class category
  const classDescriptionData = {
    General: {
      header: t('generalEnglishInfoHeader'),
      title: t('generalEnglishInfoTitle'),
      paragraph: t('generalEnglishInfoDescription1'),
      image: generalEnglishImage,
    },
    Academic: {
      header: t('academicEnglishInfoHeader'),
      title: t('academicEnglishInfoTitle'),
      paragraph: t('academicEnglishInfoDescription1'),
      image: academicEnglishImage,
    },
    Business: {
      header: t('businessEnglishInfoHeader'),
      title: t('businessEnglishInfoTitle'),
      paragraph: t('businessEnglishInfoDescription1'),
      image: businessEnglishImage,
    },
    Individual: {
      header: t('individualEnglishInfoHeader'),
      title: t('individualEnglishInfoTitle'),
      paragraph: t('individualEnglishInfoDescription1'),
      image: individualEnglishImage,
    },
  }
  return (
    <MoreCourseDetailsContainer>
      <MoreCourseDetailsContents>
        <MoreCourseDetailsHeader backgroundColor={backgroundColor}>
          <h1>{classDescriptionData[courseCategory].header}</h1>
        </MoreCourseDetailsHeader>
        <MoreCourseDetailsCardContainer>
          <MoreCourseDetailsCard>
            <img
              src={classDescriptionData[courseCategory].image}
              alt="FluentWorlds Academy Student"
            />
          </MoreCourseDetailsCard>
          <MoreCourseDetailsCard>
            <h3>{classDescriptionData[courseCategory].title}</h3>
            <p>{classDescriptionData[courseCategory].paragraph}</p>
            <ul>
              <li>{t('InfoBullet1')}</li>
              <li>{t('InfoBullet2')}</li>
              <li>{t('InfoBullet3')}</li>
              <li>{t('InfoBullet4')}</li>
              <li>{t('InfoBullet5')}</li>
            </ul>
            <p>
              {/* show "four" if its general english. "three" if its anything else */}
              {t('InfoSeparator1')}
              {courseCategory === 'General'
                ? ` ${t('InfoSeparatorNumberFour')}`
                : ` ${t('InfoSeparatorNumberThree')}`}{' '}
              {t('InfoSeparator2')}
            </p>
            {/* we have two lists to display here. The list for individual english - and the list for all the other categories */}
            {courseCategory === 'Individual' ? (
              <ul>
                <li>{t('InfoBullet6Individual')}</li>
                <li>{t('InfoBullet7Individual')}</li>
                <li>{t('InfoBullet8Individual')}</li>
              </ul>
            ) : (
              <ul className="spaced-list">
                <li>{t('InfoBullet6')}</li>
                <li>{t('InfoBullet7')}</li>
                <li>{t('InfoBullet8')}</li>
                {/* the 16 week option is only available for the general english category */}
                {courseCategory === 'General' ? (
                  <li>-{t('InfoBullet9')}</li>
                ) : null}
              </ul>
            )}
          </MoreCourseDetailsCard>
        </MoreCourseDetailsCardContainer>
      </MoreCourseDetailsContents>
    </MoreCourseDetailsContainer>
  )
}

export default MoreCourseDetailsComponent
