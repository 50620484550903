import React from 'react'

import { PlayIconCircle } from 'components/styleComponents/Buttons/PlayButtonStyles'

const PlayButton = ({ actionFunction, parameter }) => {
  return (
    <PlayIconCircle>
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <div
        className="playIconButton"
        onClick={() => actionFunction(parameter)}
        onKeyDown={() => actionFunction(parameter)}
        role="button"
        tabIndex={0}
      ></div>
    </PlayIconCircle>
  )
}

export default PlayButton
