export const FWA_GENERAL_LITE = 'General English Lite'
export const FWA_GENERAL_CLASSIC = 'General English Classic'
export const FWA_GENERAL_PREMIUM = 'General English Premium'
export const FWA_GENERAL_COMPLETE = 'General English Complete'

export const FWA_ACADEMIC_LITE = 'Academic English Lite'
export const FWA_ACADEMIC_CLASSIC = 'Academic English Classic'
export const FWA_ACADEMIC_PREMIUM = 'Academic English Premium'

export const FWA_BUSINESS_LITE = 'Business English Lite'
export const FWA_BUSINESS_CLASSIC = 'Business English Classic'
export const FWA_BUSINESS_PREMIUM = 'Business English Premium'

export const FWA_INDIVIDUAL_LITE = 'Individual English Lite'
export const FWA_INDIVIDUAL_CLASSIC = 'Individual English Classic'
export const FWA_INDIVIDUAL_PREMIUM = 'Individual English Premium'
