import styled from '@emotion/styled'

export const BuyNowButtonStyleComponent = styled.div`
  background-color: #fd7603;
  padding: 10px 35px;
  cursor: pointer;
  margin: 10px 0px;
  color: #ffffff;
  border-radius: 2px;
}
`
