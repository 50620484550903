// Module imports
import React from 'react'

// local imports
import BuyNowButton from 'components/General/Buttons/BuyNowButton'

// style imports
import {
  TitleAndImageContainer,
  TitleAndImageContents,
  TitleAndImageImage,
} from 'components/styleComponents/TitleAndImageStyles'

const TitleAndImageComponent = ({ title, subtitle, image, buttonDisplay }) => {
  return (
    <TitleAndImageContainer>
      <TitleAndImageContents>
        <h1>{title}</h1>
        <h3>{subtitle}</h3>
        <TitleAndImageImage>
          <img
            src={image}
            alt="fluent worlds students and teachers learning and teaching english"
          />
        </TitleAndImageImage>
        {buttonDisplay ? <BuyNowButton /> : null}
      </TitleAndImageContents>
    </TitleAndImageContainer>
  )
}

export default TitleAndImageComponent
