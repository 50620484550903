import {
  FWA_GENERAL_LITE,
  FWA_GENERAL_CLASSIC,
  FWA_GENERAL_PREMIUM,
  FWA_GENERAL_COMPLETE,
  FWA_ACADEMIC_LITE,
  FWA_ACADEMIC_CLASSIC,
  FWA_ACADEMIC_PREMIUM,
  FWA_BUSINESS_LITE,
  FWA_BUSINESS_CLASSIC,
  FWA_BUSINESS_PREMIUM,
  FWA_INDIVIDUAL_LITE,
  FWA_INDIVIDUAL_CLASSIC,
  FWA_INDIVIDUAL_PREMIUM,
} from 'utils/stripeTierNames'

export const getStripePriceId = (stripePriceNickName) => {
  let stripePriceId

  // map the price id to the nickname
  switch (stripePriceNickName) {
    case FWA_GENERAL_LITE:
      stripePriceId = process.env.GATSBY_FWA_STRIPE_PRICE_GENERAL_LITE
      break

    case FWA_GENERAL_CLASSIC:
      stripePriceId = process.env.GATSBY_FWA_STRIPE_PRICE_GENERAL_CLASSIC
      break

    case FWA_GENERAL_PREMIUM:
      stripePriceId = process.env.GATSBY_FWA_STRIPE_PRICE_GENERAL_PREMIUM
      break

    case FWA_GENERAL_COMPLETE:
      stripePriceId = process.env.GATSBY_FWA_STRIPE_PRICE_GENERAL_COMPLETE
      break

    case FWA_ACADEMIC_LITE:
      stripePriceId = process.env.GATSBY_FWA_STRIPE_PRICE_ACADEMIC_LITE
      break

    case FWA_ACADEMIC_CLASSIC:
      stripePriceId = process.env.GATSBY_FWA_STRIPE_PRICE_ACADEMIC_CLASSIC
      break

    case FWA_ACADEMIC_PREMIUM:
      stripePriceId = process.env.GATSBY_FWA_STRIPE_PRICE_ACADEMIC_PREMIUM
      break

    case FWA_BUSINESS_LITE:
      stripePriceId = process.env.GATSBY_FWA_STRIPE_PRICE_BUSINESS_LITE
      break

    case FWA_BUSINESS_CLASSIC:
      stripePriceId = process.env.GATSBY_FWA_STRIPE_PRICE_BUSINESS_CLASSIC
      break

    case FWA_BUSINESS_PREMIUM:
      stripePriceId = process.env.GATSBY_FWA_STRIPE_PRICE_BUSINESS_PREMIUM
      break

    case FWA_INDIVIDUAL_LITE:
      stripePriceId = process.env.GATSBY_FWA_STRIPE_PRICE_INDIVIDUAL_LITE
      break

    case FWA_INDIVIDUAL_CLASSIC:
      stripePriceId = process.env.GATSBY_FWA_STRIPE_PRICE_INDIVIDUAL_CLASSIC
      break

    case FWA_INDIVIDUAL_PREMIUM:
      stripePriceId = process.env.GATSBY_FWA_STRIPE_PRICE_INDIVIDUAL_PREMIUM
      break
    default:
      console.error(
        'Invalid Stripe Price Nickname. Check the value being passed to getStripePriceId. Here is the invalid price nickname: ',
        stripePriceNickName,
      )
  }

  return stripePriceId
}
