import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'gatsby'

import { BuyNowButtonStyleComponent } from 'components/styleComponents/Buttons/BuyNowButtonStyles'

const BuyNowButton = () => {
  const { t } = useTranslation('buyNowButton')

  return (
    <Link to="/courses">
      <BuyNowButtonStyleComponent>
        {t('buyNowButton')}
      </BuyNowButtonStyleComponent>
    </Link>
  )
}

export default BuyNowButton
