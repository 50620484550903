// shuffle array order using Fisher-Yates Algorithm
export const shuffleArray = (arr) => {
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * i)
    const temp = arr[i]
    arr[i] = arr[j]
    arr[j] = temp
  }
  return arr
}

export const isProductionEnvironment = () => {
  return process.env.NODE_ENV === 'production'
}

export const validateCourseParams = (category, option) => {
  //checks that the category and option parameters on the course page are provided, and are one of the allowed values. Returns true if so, and false if not.

  if (category === undefined || option === undefined) return false

  //ignore casing on params
  const { categoryClean, optionClean } = sanitizeCourseParams(category, option)

  if (
    categoryClean !== 'General' &&
    categoryClean !== 'Business' &&
    categoryClean !== 'Academic' &&
    categoryClean !== 'Individual'
  )
    return false

  if (
    optionClean !== 'Lite' &&
    optionClean !== 'Classic' &&
    optionClean !== 'Premium' &&
    optionClean !== 'Complete'
  )
    return false

  return true
}

export const sanitizeCourseParams = (category, option) => {
  return {
    categoryClean: category.charAt(0).toUpperCase() + category.slice(1),
    optionClean: option.charAt(0).toUpperCase() + option.slice(1),
  }
}
