// module imports
import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { useQueryParam, StringParam } from 'use-query-params'

// local imports
import Layout from 'components/Layout'
import Seo from 'components/General/SEO'
import MoreCourseDetailsComponent from './../components/CoursesPage/MoreCourseDetailsComponent'
import MeetOurStudents from 'components/General/MeetOurStudents/MeetOurStudents'
import TitleAndImage from 'components/General/TitleAndImageComponent'
import { trackClickEvent } from 'utils/analytics'
import { useAuth0 } from 'utils/react-auth0-spa'
import { getStripePriceId } from 'utils/getStripePriceId'
import { validateCourseParams, sanitizeCourseParams } from 'utils/utils'
import UserContext from 'context/user/UserContext'
import SubscriptionContext from 'context/subscription/SubscriptionContext'
import {
  LITE_COURSE_LABEL,
  CLASIC_COURSE_LABEL,
  COMPLETE_COURSE_LABEL,
  PREMIUM_COURSE_LABEL,
} from 'utils/constants'

// style imports
import {
  CoursesContainer,
  CoursesContent,
  CourseContainer,
  CourseSelection,
  CourseImages,
  CourseCheckboxes,
  AllCourseCheckboxes,
  UpgradeCourseCheckboxes,
  IndividualCheckBox,
  MoreCourseInfo,
  Price,
  BackToTop,
} from 'components/styleComponents/CoursesPage/CoursesStyles'
import { BuyNowButtonStyleComponent } from 'components/styleComponents/Buttons/BuyNowButtonStyles'

// asset imports
import GeneralImage from 'images/png/generalproduct.png'
import BusinessImage from 'images/png/businessproduct.png'
import AcademicImage from 'images/png/academicproduct.png'
import IndividualImage from 'images/png/individualproduct.png'

import joinThousandsOfStudentsImage2 from 'images/png/joinThousandsOfStudents2.png'

// this is a config option for the react-select box for the language dropdown. These configs are what set the stylings for certain parts of the select box.
const selectStyles = {
  control: () => ({
    display: 'flex',
    border: '1px #707070 solid',
    borderRadius: '5px',
    height: `2.5em`,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    width: '100px',
  }),
}

const Courses = () => {
  //get query string parameters
  const [categoryParam] = useQueryParam('category', StringParam)
  const [optionParam] = useQueryParam('option', StringParam)

  // translation const for page texts.
  const { t } = useTranslation('coursesPage')
  // Auth0 consts for checkign login status and for actually logging in.
  const { isAuthenticated } = useAuth0()
  // const variables for the subscription context - check status of a subscription update as well as to create a new subscription.
  const { createStripeCheckoutSession, subscriptionStateLoading } = useContext(
    SubscriptionContext,
  )
  // const variables for the user context - get's the users purchased products and the status of those gets.
  useContext(UserContext)

  // dictionary for looking up relevant class data per category
  // NOTE: we also need the description data for each class category, though that's not shown in this dictionary - instead it is in the MoreCourseDetailsComponent.
  const classDataObject = {
    General: {
      text: t('generalEnglishTitle'),
      price: {
        Lite: 99.99,
        Classic: 189.99,
        Premium: 269.99,
        Complete: 349.99,
      },
      duration: {
        Lite: t('nonIndividualLiteDuration'),
        Classic: t('nonIndividualClassicDuration'),
        Premium: t('nonIndividualPremiumDuration'),
        Complete: t('nonIndividualCompleteDuration'),
      },
      backgroundColor: '#f57189',
      image: GeneralImage,
    },
    Academic: {
      text: t('academicEnglishTitle'),
      price: {
        Lite: 99.99,
        Classic: 189.99,
        Premium: 269.99,
      },
      duration: {
        Lite: t('nonIndividualLiteDuration'),
        Classic: t('nonIndividualClassicDuration'),
        Premium: t('nonIndividualPremiumDuration'),
      },
      backgroundColor: '#74cdde',
      image: AcademicImage,
    },
    Business: {
      text: t('businessEnglishTitle'),
      price: {
        Lite: 99.99,
        Classic: 189.99,
        Premium: 269.99,
      },
      duration: {
        Lite: t('nonIndividualLiteDuration'),
        Classic: t('nonIndividualClassicDuration'),
        Premium: t('nonIndividualPremiumDuration'),
      },
      backgroundColor: '#aaadbf',
      image: BusinessImage,
    },
    Individual: {
      text: t('individualEnglishTitle'),
      price: {
        Lite: 249.99,
        Classic: 449.99,
        Premium: 599.99,
      },
      duration: {
        Lite: t('individualLiteDuration'),
        Classic: t('individualClassicDuration'),
        Premium: t('individualPremiumDuration'),
      },
      backgroundColor: '#d2c4b7',
      image: IndividualImage,
    },
  }

  // 5 star "rating" to be displayed in return.
  const fiveStars = (
    <span role="img" aria-label="5 stars">
      &#11088;&#11088;&#11088;&#11088;&#11088;
    </span>
  )

  const [courseCategory, setCourseCategory] = useState('General')
  const [courseOption, setCourseOption] = useState('Complete')
  const [courseText, setCourseText] = useState(t('generalEnglishTitle'))
  const [optionText, setOptionText] = useState(t('completeLabel'))
  const [weekText, setWeekText] = useState(
    classDataObject.General.duration.Lite,
  )
  const [backgroundColor, setBackgroundColor] = useState(
    classDataObject.General.backgroundColor,
  )
  const [displayedImage, setDisplayedImage] = useState(
    classDataObject.General.image,
  )
  const [coursePrice, setCoursePrice] = useState(
    classDataObject.General.price.Lite,
  )
  const [loginMessageDisplay, setLoginMessageDisplay] = useState(false)

  // these are the select menu values for the category dropdown
  const selectCategoryMenu = [
    { value: 'General', label: t('generalEnglishTitle') },
    { value: 'Business', label: t('businessEnglishTitle') },
    { value: 'Academic', label: t('academicEnglishTitle') },
    { value: 'Individual', label: t('individualEnglishTitle') },
  ]

  // these are the select menu values for the options dropdown. This one is for when "general english" is selected.
  const generalSelectOptionMenu = [
    { value: 'Lite', label: t('liteLabel') },
    { value: 'Classic', label: t('classicLabel') },
    { value: 'Premium', label: t('premiumLabel') },
    { value: 'Complete', label: t('completeLabel') },
  ]

  // these are the select menu values for the options dropdown. This one is for when the anything other than "general english" is selected. The only difference is the missing "complete" option.
  const allOtherSelectOptionMenu = [
    { value: 'Lite', label: t('liteLabel') },
    { value: 'Classic', label: t('classicLabel') },
    { value: 'Premium', label: t('premiumLabel') },
  ]

  // this function is connected to the category select drop down menu
  const handleCategoryChange = (newCategory) => {
    // this needs to update the category AND the option
    setCourseCategory(newCategory)
    setCourseOption('Lite')
  }

  // this function is connected to the option select drop down menu
  const handleOptionChange = (newOption) => {
    // this should only update the option
    setCourseOption(newOption)
    // update the displayed option text
    switch (newOption) {
      case LITE_COURSE_LABEL:
        setOptionText(t('liteLabel'))
        break
      case CLASIC_COURSE_LABEL:
        setOptionText(t('classicLabel'))
        break
      case COMPLETE_COURSE_LABEL:
        setOptionText(t('completeLabel'))
        break
      case PREMIUM_COURSE_LABEL:
        setOptionText(t('premiumLabel'))
        break
      default:
        console.error('invalid course option is selected')
    }
  }

  const onClickBuyCourse = () => {
    // Redirects the user to a Stripe checkout page with the selected product details. Note - the user MUST be logged in to make a purchase. So if they aren't logged in they have to login first.
    const stripePriceNickName = courseCategory + ' English ' + courseOption

    // log the click for google analytics
    trackClickEvent('fwaPurchaseButton', stripePriceNickName)

    // check if the user is logged in. If they aren't logged in, prompt them to log in.
    if (!isAuthenticated) {
      setLoginMessageDisplay(true)
    } else {
      // Send the user to Stripe to make their purchase.
      // get the price ID from the FWA site config (using a helper function)
      const stripePriceId = getStripePriceId(stripePriceNickName)
      createStripeCheckoutSession(
        process.env.GATSBY_FWA_PRODUCT_ID,
        stripePriceId,
      )
    }
  }

  // this use effect updates the "x weeks / y classes" text and the class specific color scheme based on the select dropdown values
  useEffect(() => {
    setDisplayedImage(classDataObject[courseCategory].image)
    setBackgroundColor(classDataObject[courseCategory].backgroundColor)
    setWeekText(classDataObject[courseCategory].duration[courseOption])
    setCoursePrice(classDataObject[courseCategory].price[courseOption])
    setCourseText(classDataObject[courseCategory].text)
    setOptionText(courseOption)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseCategory, courseOption])

  useEffect(() => {
    //check if query string parameters are provided and valid. If so they determine the initial course to display.
    if (validateCourseParams(categoryParam, optionParam)) {
      const { categoryClean, optionClean } = sanitizeCourseParams(
        categoryParam,
        optionParam,
      )

      setCourseCategory(categoryClean)
      setCourseOption(optionClean)

      setDisplayedImage(classDataObject[categoryClean].image)
      setBackgroundColor(classDataObject[categoryClean].backgroundColor)
      setWeekText(classDataObject[categoryClean].duration[optionClean])
      setCoursePrice(classDataObject[categoryClean].price[optionClean])
      setCourseText(classDataObject[categoryClean].text)
      setOptionText(optionClean)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout>
      <Seo title={t('seoTitle')} description={t('seoDescription')} />
      <CoursesContainer>
        <CoursesContent>
          <h1>{t('coursesPageTitle')}</h1>
          <h3>{t('coursesPageSubtitle')}</h3>
          <h3>{t('coursesPageInstructions')}</h3>
          <CourseContainer>
            <CourseImages>
              <div className="course-image-container">
                <img
                  src={displayedImage}
                  alt="Students of FluentWorlds Academy"
                />
              </div>
            </CourseImages>
            <CourseCheckboxes backgroundColor={backgroundColor}>
              <h3 className="course-header">{`${courseText} ${optionText}`}</h3>
              <h3>{weekText}</h3>
              <div className="course-stars">{fiveStars}</div>
              <AllCourseCheckboxes>
                <IndividualCheckBox backgroundColor={backgroundColor}>
                  <div className="checkicon">&#x2713;</div>
                  <p>{t('checkbox1')}</p>
                </IndividualCheckBox>
                <IndividualCheckBox backgroundColor={backgroundColor}>
                  <div className="checkicon">&#x2713;</div>
                  <p>{t('checkbox2')}</p>
                </IndividualCheckBox>
                <IndividualCheckBox backgroundColor={backgroundColor}>
                  <div className="checkicon">&#x2713;</div>
                  <p>{t('checkbox3')}</p>
                </IndividualCheckBox>
              </AllCourseCheckboxes>
              <div className="upgrade-header">
                {courseCategory === 'General'
                  ? t('upgradeCheckboxesTitle1')
                  : t('upgradeCheckboxesTitle2')}
              </div>
              <UpgradeCourseCheckboxes>
                <IndividualCheckBox backgroundColor={backgroundColor}>
                  {/* only show the checkicon if the premium or complete are the selected option. Also strike out the paragraph text on the same conditions. */}
                  <div className="checkicon">
                    {courseOption === 'Classic' ||
                    courseOption === 'Premium' ||
                    courseOption === 'Complete' ? (
                      <span>&#x2713;</span>
                    ) : null}
                  </div>
                  <p
                    className={
                      courseOption === 'Classic' ||
                      courseOption === 'Premium' ||
                      courseOption === 'Complete'
                        ? null
                        : 'strikethrough'
                    }
                  >
                    {t('checkbox4')}
                  </p>
                </IndividualCheckBox>
                {/* This particular checkbox should NOT be displayed if the user has selected "individual english" */}
                {courseCategory === 'Individual English' ? null : (
                  <IndividualCheckBox backgroundColor={backgroundColor}>
                    {/* only show the checkicon if the premium or complete are the selected option. Also strike out the paragraph text on the same conditions. */}
                    <div className="checkicon">
                      {courseOption === 'Premium' ||
                      courseOption === 'Complete' ? (
                        <span>&#x2713;</span>
                      ) : null}
                    </div>
                    <p
                      className={
                        courseOption === 'Premium' ||
                        courseOption === 'Complete'
                          ? null
                          : 'strikethrough'
                      }
                    >
                      {t('checkbox5_1')}
                      <br />
                      <em>{t('checkbox5_2')}</em>
                    </p>
                  </IndividualCheckBox>
                )}
              </UpgradeCourseCheckboxes>
              <MoreCourseInfo backgroundColor={backgroundColor}>
                <p>{t('moreBelow')}</p>
                <div className="down-arrow"></div>
              </MoreCourseInfo>
            </CourseCheckboxes>
            <CourseSelection>
              <h3>{t('selectCategory')}</h3>
              <Select
                styles={selectStyles}
                value={courseCategory}
                placeholder={courseCategory}
                onChange={(ev) => handleCategoryChange(ev.value)}
                options={selectCategoryMenu}
              />
              <h3>{t('selectOption')}</h3>
              <Select
                styles={selectStyles}
                value={courseOption}
                placeholder={courseOption}
                onChange={(ev) => handleOptionChange(ev.value)}
                options={
                  courseCategory === 'General'
                    ? generalSelectOptionMenu
                    : allOtherSelectOptionMenu
                }
              />
              <Price>{`$${coursePrice} USD`}</Price>
              {subscriptionStateLoading ? (
                <p>{t('processing')}</p>
              ) : (
                <BuyNowButtonStyleComponent onClick={onClickBuyCourse}>
                  {t('buyNowButton')}
                </BuyNowButtonStyleComponent>
              )}

              {!loginMessageDisplay ? null : (
                <p>{t('mustBeSignedInMessage')}</p>
              )}
            </CourseSelection>
          </CourseContainer>
          <MoreCourseDetailsComponent
            courseCategory={courseCategory}
            backgroundColor={backgroundColor}
          />
          <MeetOurStudents title={t('studentComponent')} buyNowToggle={false} />
          <TitleAndImage
            title={t('titleAndImageTitle')}
            subtitle={t('titleAndImageSubtitle')}
            image={joinThousandsOfStudentsImage2}
            buttonDisplay={false}
          />
          <BackToTop backgroundColor={backgroundColor}>
            <div className="up-arrow"></div>
            <a href="#logo">{t('backToTop')}</a>
          </BackToTop>
        </CoursesContent>
      </CoursesContainer>
    </Layout>
  )
}

export default Courses
