import styled from '@emotion/styled'

export const TitleAndImageContainer = styled.div`
  position: relative;
  width: 100vw;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 50px;
`
export const TitleAndImageContents = styled.div`
  position: relative;
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    font-weight: normal;
    margin-bottom: 30px;
  }
`
export const TitleAndImageImage = styled.div`
  width: 100%;
  img {
    max-height: 100%;
    max-width: 100%;
  }
`
