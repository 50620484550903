import { isProductionEnvironment } from 'utils/utils'

export const trackClickEvent = (category, label) => {
  if (typeof window !== 'undefined' && isProductionEnvironment()) {
    window.gtag('event', 'click', {
      event_category: category,
      event_label: label,
    })
  }
}
